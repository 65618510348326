<template>
  <div v-if="characteristicsDescription.length > 0">
    <div class="description">
      <div
        v-if="productCharacteristics"
        class="description-list"
      >
        <p
          v-for="char in productCharacteristics"
          :key="char.slug"
        >
          <strong>{{ char?.nameForProductCard || char?.name }}</strong>
          <div v-html="char.values?.[0]?.value"/>
        </p>
      </div>
      <div v-else-if="description">
        <div
          class="description-list"
          v-html="description"
        />
      </div>

      <div
        v-for="(item, index) in characteristicsDescription"
        v-else
        :key="`description-item-${ index }`"
        class="description-item"
      >
        <div class="description-item__title">
          {{ item.name }}
        </div>
        <div class="description-item__message">
          <div
            v-for="(itm, indexP) in item.values"
            :key="`description-item-${index}-${indexP}`"
            v-html="itm.value"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductCharacteristic, ProductCharacteristics } from '#sitis/internal/controllers/products/models/Product';

const { description = '', characteristicsDescription, characteristics } = defineProps<{
	description:string;
	characteristicsDescription: ProductCharacteristic[];
  characteristics: ProductCharacteristics[];
}>();

const productCharacteristics = computed(() => {
  const _characteristics = characteristics.find((characteristicsGroup) => characteristicsGroup.name === 'Описание коллекции')
  return _characteristics?.characteristics;
})
</script>

<style lang="scss">
.description-list {
  & > p {
    font-size: 16px;
    line-height: 25px;
    color: #000;
    margin: 16px 0;
  }
}

.description {
  font-size: 15px;
  line-height: 24px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  p {
    display: flex;
    flex-direction: column;
  }

  * {
    display: block;
  }

  & > * {
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.description-item {
}

.description-item__title {
  font-size: 19px;
  line-height: 24px;
}

.description-item__message {
  font-size: 15px;
  line-height: 24px;

  & > * {
    margin-top: 5px;
  }
}
</style>
<style lang="scss">
.collection-description {
  p {
    margin-bottom: 10px;
  }
}
</style>
